.ewallet-kiosk {
  margin: 0;
  font-family: 'avenirHeavy', 'avenir', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

@font-face {
  font-family: 'avenir';
  src: url('https://static-dev.innovorder.fr/frontkit/fonts/Avenir-Book.ttf');
}

@font-face {
  font-family: 'avenirHeavy';
  src: url('https://static-dev.innovorder.fr/frontkit/fonts/Avenir-Heavy.ttf');
}
